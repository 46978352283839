import * as React from "react";
import { FC } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { motion, useTransform, useScroll } from "framer-motion"
import Layout from "../layout/Layout";
import InViewDiv from "../ui/inViewItem";
import { IProjectProps, TypeImage, TypeLocale } from "../../types";
import { fadeLeft, fadeRight, fadeUp, zoomIn } from "../_animationVariants";
import { createLabel, imgAlt } from "../../utilities";
import { makeButtonClass } from "../cssClasses";
import ProjectLogo from "./ProjectLogo";
import HeadMeta from "../layout/HeadMeta";

type ProjectImages = {
    nodes: TypeImage[]
}

interface IPageContext extends IProjectProps {
    locale: TypeLocale
    prevProject: IProjectProps
    nextProject: IProjectProps
}

interface ISingleProject {
    data: {
        images: ProjectImages
        brand: ProjectImages
    }
    pageContext: IPageContext
}

const SingleProject: FC<ISingleProject> = ( { data, pageContext } ) => {
    const { locale: l } = pageContext
    const project = pageContext
    const { scrollY } = useScroll();
    const y2 = useTransform( (scrollY), [ 0, 800 ], [ 0, 400 ] );
    const brandImg = data.brand.nodes;
    const path = `projects/${ project.doc.slug }`
    const ogImage = data.images.nodes && data.images.nodes.find( ( node: TypeImage ) => node.relativePath === `${ path }/featured.jpg` ) as TypeImage
    const intro = data.images.nodes && data.images.nodes.find( ( node: TypeImage ) => node.relativePath === `${ path }/intro.png` ) as TypeImage
    return (
        <Layout>
            <HeadMeta title={ project.doc.name } description={ project.doc.description[l] } image={ ogImage.childImageSharp.original.src }/>
            <motion.section className={ "text--center overflow--hidden relative bg--bg" } variants={ fadeUp }>
                <motion.div style={ { y: y2 } } className={ "w--100 h--100" }>
                    <GatsbyImage
                        image={ intro.childImageSharp.gatsbyImageData }
                        className={ "max-h--v75 z--2" }
                        imgStyle={ { objectFit: "contain" } }
                        alt={ imgAlt( intro.relativePath, project.doc.name ) }/>
                </motion.div>
            </motion.section>
            <article className={ "container px--xl" }>
                <section className={ "py--huge" }>
                    <Link to={ `/projects/category/${ project.doc.categories }` } className={ makeButtonClass( "accent", "round--circle mb--lg" ).outline }>
                        { createLabel( project.doc.categories ) }
                    </Link>
                    <h1>{ project.doc.name }</h1>
                    { project.doc.technologies && project.doc.technologies.map( technology =>
                        <span key={ technology } className={ "text--textDimmed round--circle px--sm py--xs border border--textDimmed inline-flex mr--sm mt--lg" }>
                            { technology }
                        </span>
                    ) }
                    <p className={ "my--lg" }>{ project.doc.description[l] }</p>
                    { project.doc.url && <a target={ "_blank" } rel={ "noopener noreferrer" } className={ makeButtonClass( "accent" ).fill } href={ project.doc.url }>Visit Website</a> }
                </section>
                { project.doc.logo && <ProjectLogo logo={ project.doc.logo } variantLeft={ fadeLeft } variantRight={ fadeRight }/> }
                { brandImg && brandImg.map( img => {
                    return (
                        <InViewDiv key={ img.relativePath } variants={ zoomIn } className={ "mb--md" }>
                            <GatsbyImage
                                image={ img.childImageSharp.gatsbyImageData }
                                className={ "h--100" }
                                alt={ imgAlt( img.relativePath, project.doc.name ) }
                            />
                        </InViewDiv>
                    )
                } ) }
            </article>
            <nav className={ "container flex--center jf__cnt--between px--xl py--md" }>
                <Link to={ `/projects/${ project.prevProject.doc.slug }` } className={ makeButtonClass( "accent", "round--circle" ).minimal }>
                    <svg fill={ "var(--accent)" } xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 24 24">
                        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
                    </svg>
                    { project.prevProject.doc.name }
                </Link>
                <Link to={ `/projects/${ project.nextProject.doc.slug }` } className={ makeButtonClass( "accent", "round--circle" ).minimal }>
                    { project.nextProject.doc.name }
                    <svg fill={ "var(--accent)" } xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 24 24">
                        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
                    </svg>
                </Link>
            </nav>
        </Layout>
    )
}

export default SingleProject

export const query = graphql`
    query ($imgPath: String!, $brandImg: String!) {
        images: allFile(
            sort: {fields: name}
            filter: {extension: {regex: "/jpeg|jpg|png|gif/"}, relativeDirectory: {eq: $imgPath}}
        ) {
            nodes {
                relativePath
                childImageSharp {
                    original {
                        src
                    }
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
        brand: allFile(
            sort: {fields: name}
            filter: {extension: {regex: "/jpeg|jpg|png|gif/"}, relativeDirectory: {eq: $brandImg}}
        ) {
            nodes {
                relativePath
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
`
