import * as React from "react";
import { FunctionComponent } from "react";
import { IntersectionOptions, useInView } from "react-intersection-observer";
import { motion, Variants } from "framer-motion";

interface InViewItemProps {
    className?: string
    variants: Variants
    observerSettings?: IntersectionOptions
    dangerouslySetInnerHTML?: {
        __html: string;
    };
}

const InViewDiv: FunctionComponent<InViewItemProps> = ( { variants, children, observerSettings, className, dangerouslySetInnerHTML } ) => {
    const [ ref, inView ] = useInView( {
        rootMargin : "0px 0px -200px 0px",
        triggerOnce: true,
        ...observerSettings
    } )

    return (
        <motion.div ref={ ref }
                    variants={ variants }
                    initial={ "init" }
                    animate={ inView ? "in" : "out" }
                    className={ className }
                    dangerouslySetInnerHTML={ dangerouslySetInnerHTML }
        >
            { children }
        </motion.div>
    )
}

export default InViewDiv
