import * as React from "react";
import { FunctionComponent, memo } from "react";
import { Variants } from "framer-motion";
import { sanitizedHTML } from "../../utilities";
import InViewDiv from "../ui/inViewItem";

interface ProjectLogoProps {
    logo: string
    variantLeft: Variants
    variantRight: Variants
}

const ProjectLogo: FunctionComponent<ProjectLogoProps> = memo( ( { logo, variantLeft, variantRight } ) => {
    return (
        <div className={ "lg:flex" }>
            <InViewDiv variants={ variantLeft }
                       className={ "project-logo bg--gray-1 bg--light h--50 flex--center-middle lg:col--6 mb--md" }
                       dangerouslySetInnerHTML={ sanitizedHTML( logo ) }
            />
            <InViewDiv variants={ variantRight }
                       className={ "project-logo bg--gray-8 bg--dark h--50 flex--center-middle lg:col--6 mb--md" }
                       dangerouslySetInnerHTML={ sanitizedHTML( logo ) }
            />
        </div>
    )
} )

export default ProjectLogo
